import React from 'react';
import { Grid, CircularProgress } from '@mui/material';
import Layout from './Layout';

const CircularIndeterminate = () => {
  return (
    <Layout>
      <Grid container maxWidth="xl"
        sx={{
          display: 'flex',
          '& > * + *': {
            ml: 6,
          },
          height: 600,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Grid>
    </Layout>
  );
};

export default CircularIndeterminate;
