import React from 'react';
import { Divider, List, Toolbar } from '@mui/material';

import { SIDE_NAV, SIDE_NAV2 } from '../../config/side-menu';
import SubMenu from './SubMenu';

const SideMenu = () => {
  return (
    <>
      <Toolbar />
      <Divider />
      <List>
        {SIDE_NAV.map((item, index) => (
          <SubMenu item={item} index={index} key={item.id} />
        ))}
      </List>
      <Divider />
      <List>
        {SIDE_NAV2.map((item, index) => (
          <SubMenu item={item} index={index} key={item.id} />
        ))}
      </List>
    </>
  );
};

export default SideMenu;
