import loadable from '@loadable/component';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Main = loadable(() => import('../../pages/Main'));
const ReportsMain = loadable(() => import('../../pages/reports/ReportsMain'));
const RolesMain = loadable(() => import('../../pages/admin/roles/RolesMain'));
const PermissionsMain = loadable(() => import('../../pages/admin/permissions/PermissionsMain'));
const RulesMain = loadable(() => import('../../pages/admin/rules/RulesMain'));

const PayersMain = loadable(() => import('../../pages/admin/payers/PayersMain'));
const ProgramsMain = loadable(() => import('../../pages/admin/programs/ProgramsMain'));
const LocationsMain = loadable(() => import('../../pages/admin/locations/LocationsMain'));
const StaffMain = loadable(() => import('../../pages/admin/staff/StaffMain'));
const ActivitiesMain = loadable(() => import('../../pages/admin/activities/ActivitiesMain'));
const SysProfilesMain = loadable(() => import('../../pages/admin/sys-profiles/SysProfilesMain'));
const AppointmentsMain = loadable(() => import('../../pages/main/appointments/AppointmentsMain'));

const PatientsMain = loadable(() => import('../../pages/main/patients/PatientsMain'));
const ActivityLogsMain = loadable(() => import('../../pages/main/activity-logs/ActivityLogsMain'));

const Login = loadable(() => import('../../pages/auth/Login'));
const Profile = loadable(() => import('../../pages/auth/Profile'));
const NotFound = loadable(() => import('../../pages/NotFound'));
const ChangePassword = loadable(() => import('../../pages/auth/ChangePassword'));
const ForgotPassword = loadable(() => import('../../pages/auth/ForgotPassword'));
const ResetPassword = loadable(() => import('../../pages/auth/ResetPassword'));

const AppRoutes = () => {
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    return (
        <Switch>
            <Route exact path="/auth/login" component={Login} />
            <Route exact path="/auth/forgot-password" component={ForgotPassword} />
            <Route exact path="/auth/reset-password/:key" component={ResetPassword} />
            {!isAuthenticated && <Redirect to="/auth/login" />}
            <Route exact path="/" component={Main} />
            <Route exact path="/auth/profile" component={Profile} />
            <Route exact path="/auth/change-password" component={ChangePassword} />
            <Route path="/reports" component={ReportsMain} />
            <Route path="/patients" component={PatientsMain} />
            <Route path="/activity-logs" component={ActivityLogsMain} />
            <Route path="/appointments" component={AppointmentsMain} />
            {/* <Route path="/admin/users" component={UsersMain} /> */}
            <Route path="/admin/rules" component={RulesMain} />
            <Route path="/admin/roles" component={RolesMain} />
            <Route path="/admin/permissions" component={PermissionsMain} />
            <Route path="/admin/programs" component={ProgramsMain} />
            <Route path="/admin/locations" component={LocationsMain} />
            <Route path="/admin/payers" component={PayersMain} />
            <Route path="/admin/activities" component={ActivitiesMain} />
            <Route path="/admin/staff" component={StaffMain} />
            <Route path="/admin/sys-profiles" component={SysProfilesMain} />
            <Route component={NotFound} />
        </Switch>
    );
};

export default AppRoutes;
