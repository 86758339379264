import * as React from 'react';
import { Snackbar, Stack } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// import { Box } from '@mui/system';
import { useState } from 'react';

// const Alert = (props) => {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// };
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const PhAlertMessage = ({ message, severity, closeHandler }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
    closeHandler();
  };

  return (
    <Stack spacing={2} sx={{ width: '100%' }}>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>

    // <Box sx={{
    //     width: '100%',
    //     '& > * + *': {
    //       mt: 3,
    //     },
    //   }}
    // >
    //   <Snackbar
    //     open={open}
    //     autoHideDuration={6000}
    //     onClose={handleClose}
    //     anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    //     message={message}
    //   />
    //   {/* <Alert onClose={handleClose} severity={severity}>
    //       {message}
    //     </Alert>
    //   </Snackbar> */}
    // </Box>
  );
};

export default PhAlertMessage;
