export const SIDE_NAV = [
    {
        title: 'Login',
        pageUrl: '/auth/login',
        id: 'login-menu',
        icon: null,
        role: 'not-logged-in',
        perm: null,
        items: null,
    },
    {
        title: 'Calendar',
        pageUrl: '/appointments/calendar',
        id: 'calendar-menu',
        icon: 'Schedule',
        role: 'login-user-role',
        perm: null,
        items: null,
    },
    {
        title: 'Appointments',
        pageUrl: '/appointments',
        id: 'appointment-menu',
        icon: 'Schedule',
        role: 'login-user-role',
        perm: null,
        items: null,
    },
    {
        title: 'Time',
        pageUrl: '/activity-logs',
        id: 'time-menu',
        icon: 'ViewList',
        role: 'login-user-role',
        perm: null,
        items: null,
    },
    {
        title: 'Patients',
        pageUrl: '/patients',
        id: 'patient-menu',
        icon: 'ViewList',
        role: 'login-user-role',
        perm: null,
        items: null,
    },
    {
        title: 'Reports',
        pageUrl: '/reports',
        id: 'reports-menu',
        icon: null,
        role: 'login-user-role',
        perm: null,
        items: [
            {
                title: 'Reports',
                pageUrl: '/reports',
                role: 'login-user-role',
                perm: null,
                id: 'reports-menu-m1',
            },
            {
                title: 'Services Allocation Report',
                pageUrl: '/reports/services-allocation',
                role: 'login-user-role',
                perm: 'report:services-allocation',
                op: 'and',
                id: 'reports-menu-m3',
            },
            {
                title: 'Staff Services Detail Report',
                pageUrl: '/reports/staff-services-detail',
                role: 'login-user-role',
                perm: 'report:staff-services-detail',
                op: 'and',
                id: 'reports-menu-m4',
            },
            {
                title: 'Staff Services Summary Report',
                pageUrl: '/reports/staff-services-summary',
                role: 'login-user-role',
                perm: 'report:staff-services-summary',
                op: 'and',
                id: 'reports-menu-m5',
            },
            {
                title: 'Program Allocation Report',
                pageUrl: '/reports/program-allocation',
                role: 'login-user-role',
                perm: 'report:program-allocation',
                op: 'and',
                id: 'reports-menu-m6',
            },
            {
                title: 'Program Allocation Detail Report',
                pageUrl: '/reports/program-allocation-detail',
                role: 'login-user-role',
                perm: 'report:program-allocation-detail',
                op: 'and',
                id: 'reports-menu-m10',
            },
            {
                title: 'Patient Invoice',
                pageUrl: '/reports/patient-invoice',
                role: 'login-user-role',
                perm: 'report:patient-invoice',
                op: 'and',
                id: 'reports-menu-m7',
            },
            {
                title: 'Patient Services Detail',
                pageUrl: '/reports/patient-services-detail',
                role: 'login-user-role',
                perm: 'report:patient-services-detail',
                op: 'and',
                id: 'reports-menu-m8',
            },
            {
                title: 'All Others Report',
                pageUrl: '/reports/all-others',
                role: 'login-user-role',
                perm: 'report:all-others',
                op: 'and',
                id: 'reports-menu-m9',
            },
        ],
    },
];

export const SIDE_NAV2 = [
    {
        title: 'Admin',
        pageUrl: null,
        role: ['administrator', 'appadmin'],
        perm: null,
        id: 'admin-menu',
        icon: null,
        items: [
            {
                title: 'Staff',
                pageUrl: '/admin/staff',
                role: 'administrator',
                perm: 'staff:index',
                op: 'and',
                id: 'm1',
            },
            {
                title: 'Programs',
                pageUrl: '/admin/programs',
                role: null,
                perm: 'program:index',
                id: 'm5',
            },
            {
                title: 'Locations',
                pageUrl: '/admin/locations',
                role: null,
                perm: 'location:index',
                id: 'm9',
            },
            {
                title: 'Activities',
                pageUrl: '/admin/activities',
                role: null,
                perm: 'activity:index',
                id: 'm6',
            },
            {
                title: 'Payers',
                pageUrl: '/admin/payers',
                role: null,
                perm: 'payer:index',
                id: 'm7',
            },
            {
                title: 'Roles',
                pageUrl: '/admin/roles',
                role: null,
                perm: 'rbacuser:role:index',
                id: 'm2',
            },
            {
                title: 'Permissions',
                pageUrl: '/admin/permissions',
                role: null,
                perm: 'rbacuser:permission:index',
                id: 'm3',
            },
            {
                title: 'Rules',
                pageUrl: '/admin/rules',
                role: null,
                perm: 'rbacuser:rule:index',
                id: 'm4',
            },
            {
                title: 'System Profiles',
                pageUrl: '/admin/sys-profiles',
                role: null,
                perm: 'sys-profile:index',
                id: 'm8',
            },
        ],
    },
];
