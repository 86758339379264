import { Typography, Grid, Box } from '@mui/material';

import { COPYRIGHT, COPYRIGHT_LINK, COPYRIGHT_DATES } from '../../config/config';

const Footer = () => {
    return (
        <Box
            component="footer"
            sx={{
                bgcolor: 'grey.200',
                padding: '20px 0',
                borderTopStyle: 'solid',
                borderColor: 'grey.300',
                bottom: '0',
                zIndex: (theme) => theme.zIndex.drawer + 1,
            }}
        >
            <Grid container direction="column">
                <Grid item xs={12} sm={4} />
                <Grid item xs={12} sm={4}>
                    <Typography variant="body2" align="center" gutterBottom>
                        &copy; {COPYRIGHT_DATES} <a href={COPYRIGHT_LINK}>{COPYRIGHT}</a>
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4} />
            </Grid>
        </Box>
    );
};

export default Footer;
