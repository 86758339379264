import dotenv from 'dotenv';

dotenv.config();

export const baseUrl = window.REACT_APP_API_URL ? window.REACT_APP_API_URL : process.env.REACT_APP_API_URL;

export const urls = {
    patient: '/patients',
    patientAdmission: '/patient-admissions',
    patientContact: '/patient-contacts',
    patientPayer: '/patient-payers',
    patientNote: '/patient-notes',
    appointment: '/appointments',
    payer: '/payers',
    payerRate: '/payer-rates',
    activity: '/activities',
    location: '/locations',
    program: '/programs',
    programRate: '/program-rates',
    staff: '/staff',
    role: '/rbacuser/roles',
    permission: '/rbacuser/permissions',
    rule: '/rbacuser/rules',
    user: '/rbacuser/users',
    assignment: '/rbacuser/assignments',
    activityLog: '/activity-logs',
    auth: '/rbacuser/auth',
    sysProfile: '/sysprofile/sys-profiles',
    report: '/reports',
};

export const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const drawerWidth = 240;

const curDate = new Date();

export const TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const TITLE = 'Monroe County Public Health';
export const COPYRIGHT = 'Pella Hosting & Website Solutions';
export const COPYRIGHT_DATES = `2021 - ${curDate.getFullYear()}`;
export const COPYRIGHT_LINK = 'https://pellahosting.com';
