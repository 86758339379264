import { MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkAuth } from '../../lib/check-auth';

const SubMenu2 = ({ item, index, clickHandler }) => {
    const authData = useSelector((state) => state.auth);
    let isAuthorized = checkAuth(authData, { role: item.role, perm: item.perm, op: item.op });

    if (isAuthorized) {
        return (
            <MenuItem key={index} onClick={clickHandler} component={Link} to={item.pageUrl}>
                {item.title}
            </MenuItem>
        );
    }

    return <></>;
};

export default SubMenu2;
