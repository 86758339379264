/**
 * @name lib/check-auth.js
 * @description Determine if user has the appropriate role/permissions to
 *      to access the given task
 *
 * Note: If the caller sends an array of roles or permissions, the user will
 *  meet the criteria for role/permission if they have any of the values in the array.
 *
 *  The op flag is only used when comparing the role/permission. If "and", then
 *  the user must have both the role and the permission, if "or", the user can
 *  be authorized if they have either the role or the permission.
 */

export const checkAuth = (authData, { role, perm, op }) => {
    let isAuthorized;

    if (!role && !perm) {
        // role/permission is not there - allow it
        isAuthorized = true;
    } else {
        let isAuthorizedRole = false;
        let isAuthorizedPerm = false;

        if (role && Array.isArray(role)) {
            role.forEach((element) => {
                if (authData.roles.includes(element)) {
                    isAuthorizedRole = true;
                }
            });
        } else {
            isAuthorizedRole = role && authData.roles.includes(role);
        }

        if (perm && Array.isArray(perm)) {
            perm.forEach((element) => {
                if (authData.permissions.includes(element)) {
                    isAuthorizedPerm = true;
                }
            });
        } else {
            isAuthorizedPerm = perm && authData.permissions.includes(perm);
        }

        if (!op || op === 'or') {
            isAuthorized = isAuthorizedRole || isAuthorizedPerm;
            // (role && authData.roles.includes(role)) ||     // check role if specifying it
            // (perm && authData.permissions.includes(perm)); // check perm if specifying it
        } else if (op === 'and') {
            isAuthorized = isAuthorizedRole && isAuthorizedPerm;
            // isAuthorized =
            // (role && authData.roles.includes(role)) &&     // check role if specifying it
            // (perm && authData.permissions.includes(perm)); // check perm if specifying it
        }
    }

    return isAuthorized;
};
