import { createSlice } from '@reduxjs/toolkit';

const drawerSlice = createSlice({
  name: 'drawer',
  initialState: { mobileOpen: false },
  reducers: {
    toggle(state) {
      state.mobileOpen = !state.mobileOpen;
    },
  },
});

export const drawerActions = drawerSlice.actions;

export default drawerSlice.reducer;
