import React from 'react';
import { CssBaseline } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { authActions } from './store/auth-slice';
import { messageActions } from './store/message-slice';
import { getWhoami } from './lib/auth';

import useHttp from './hooks/use-http';
import MainLayout from './components/UI/MainLayout';
import CircularIndeterminate from './components/UI/CircularIndeterminate';
import AppRoutes from './components/common/AppRoutes';

// import theme from './theme';
import PhAlertMessage from './components/UI/PhAlertMessage';

const App = () => {
    // const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const { message, severity } = useSelector((state) => state.message);
    const { sendRequest, status } = useHttp(getWhoami, true);
    const dispatch = useDispatch();

    useEffect(() => {
        const processWhoami = (data) => {
            if (data.success) {
                dispatch(
                    authActions.login({
                        name: `${data.response.user.firstname} ${data.response.user.lastname}`,
                        username: data.response.user.username,
                        permissions: data.response.permissions,
                        roles: data.response.roles,
                        staff_id: data.response.staff ? data.response.staff.id : null,
                    })
                );
            }
        };

        sendRequest(null, processWhoami);
    }, [sendRequest, dispatch]);

    const isPending = status === 'pending';

    return (
        // <StyledEngineProvider injectFirst>
        <>
            <CssBaseline />
            <MainLayout>
                {isPending ? (
                    <CircularIndeterminate />
                ) : (
                    <>
                        {message && (
                            <PhAlertMessage
                                message={message}
                                severity={severity}
                                closeHandler={() => {
                                    dispatch(messageActions.clear());
                                }}
                            />
                        )}
                        <AppRoutes />
                    </>
                )}
            </MainLayout>
        </>
    );
};

export default App;
