import { useReducer, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { authActions } from '../store/auth-slice';

function httpReducer(state, action) {
    if (action.type === 'SEND') {
        return {
            data: null,
            error: null,
            status: 'pending',
        };
    }

    if (action.type === 'SUCCESS') {
        return {
            data: action.responseData,
            error: null,
            status: 'completed',
        };
    }

    if (action.type === 'ERROR') {
        return {
            data: null,
            error: action.errorMessage,
            status: 'completed',
        };
    }

    return state;
}

function useHttp(requestFunction, startWithPending = false) {
    const dispatchRedux = useDispatch();
    const [httpState, dispatch] = useReducer(httpReducer, {
        status: startWithPending ? 'pending' : null,
        data: null,
        error: null,
    });

    const sendRequest = useCallback(
        async function (requestData, processData) {
            dispatch({ type: 'SEND' });
            try {
                const responseData = await requestFunction(requestData);

                if (responseData.success) {
                    if (processData) {
                        processData(responseData);
                    }
                    dispatch({ type: 'SUCCESS', responseData });
                } else {
                    if (responseData.response.statusCode === 401) {
                        dispatchRedux(
                            authActions.logout({
                                message: 'You are not logged in.',
                            })
                        );
                        // } else if (responseData.response.statusCode === 403) {
                        //     dispatch({
                        //         type: 'ERROR',
                        //         errorMessage: responseData.response.message,
                        //     });
                    } else {
                        dispatch({
                            type: 'ERROR',
                            errorMessage: `An error occurred ${responseData.response.statusCode}:${responseData.response.message}`,
                        });
                    }
                }
            } catch (error) {
                dispatch({
                    type: 'ERROR',
                    errorMessage: error.message || 'Something went wrong!',
                });
            }
        },
        [requestFunction, dispatchRedux]
    );

    return {
        sendRequest,
        ...httpState,
    };
}

export default useHttp;
