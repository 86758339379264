/**
 * @name lib/auth.js
 * @description Utilities to handle authorization functions
 */

import { postRequest, getRequest, putRequest } from './api-call';

const functionUrl = '/rbacuser/auth';

/**
 * @name loginUser
 * @description Function to log a user in to the backend
 *
 * @param {object} username, password
 * @returns null
 */
export const loginUser = async ({ username, password }) => {
    let result;

    try {
        result = await postRequest({
            url: `${functionUrl}/login`,
            body: {
                username: username,
                password: password,
            },
        });
    } catch (err) {
        throw err;
    }

    return result;
};

/**
 * @name logoutUser
 * @description Logout the user
 */
export const logoutUser = async () => {
    let result;
    try {
        result = await getRequest(`${functionUrl}/logout`);
    } catch (err) {
        throw err;
    }

    return result;
};

export const changePassword = async (values) => {
    let result;

    try {
        result = await postRequest({ url: `${functionUrl}/change-password`, body: values });
    } catch (err) {
        throw err;
    }

    return result;
};

/**
 * @name getWhoami
 * @description Make API call to whoami and set login
 */
export const getWhoami = async () => {
    let result;

    try {
        result = await getRequest('/auth/whoami');
    } catch (err) {
        throw err;
    }

    return result;
};

/**
 * @name getPermissions
 * @description Get the permissions for the user and store in redux
 */
export const getPermissions = async () => {
    let result;

    try {
        result = await getRequest(`${functionUrl}/permissions`);
    } catch (err) {
        throw err;
    }

    return result;
};

/**
 * @name getRoles
 * @description Get the user's roles and store in redux
 */
export const getRoles = async () => {
    let result;

    try {
        result = await getRequest(`${functionUrl}/roles`);
    } catch (err) {
        throw err;
    }

    return result;
};

/**
 * @name getProfile
 * @description Function to get the profile information
 *
 * @returns null
 */
export const getProfile = async () => {
    let result;

    try {
        result = await getRequest(`${functionUrl}/my-profile`);
    } catch (err) {
        throw err;
    }

    return result;
};

export const updateProfile = async (body) => {
    let result;

    try {
        result = await putRequest(`${functionUrl}/update-my-profile`, body);
    } catch (err) {
        throw err;
    }

    return result;
};
