const USER_MENU = [
    {
        title: 'Profile',
        pageUrl: '/auth/profile',
        id: 'm1',
    },
    {
        title: 'Change Password',
        pageUrl: '/auth/change-password',
        id: 'm2',
    },
    {
        title: 'Logout',
        pageUrl: '/auth/login',
        id: 'm3',
    },
];

export default USER_MENU;
