import { createSlice } from '@reduxjs/toolkit';

const initialMessageState = {
    message: '',
    severity: '',
};

const messageSlice = createSlice({
    name: 'message',
    initialState: initialMessageState,
    reducers: {
        create(state, action) {
            state.message = action.payload.message;
            state.severity = action.payload.severity;
        },
        clear(state, action) {
            state.message = '';
            state.severity = '';
        },
    },
});

export const messageActions = messageSlice.actions;

export default messageSlice.reducer;
