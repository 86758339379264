import React from 'react';
import { Typography, IconButton, Menu, MenuItem, Button } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';

import USER_MENU from '../../config/user-menu';
import { authActions } from '../../store/auth-slice';
import { messageActions } from '../../store/message-slice';
import { getRequest } from '../../lib/api-call';
import { urls } from '../../config/config';

const TopMenu = (props) => {
    const { isAuthenticated, name: displayName } = useSelector((state) => state.auth);
    const history = useHistory();
    const dispatch = useDispatch();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuClick = async (item) => {
        let result;

        if (item.title === 'Logout') {
            try {
                result = await getRequest(`${urls.auth}/logout`);
            } catch (err) {
                dispatch(messageActions.create({ message: err.message, severity: 'error' }));
            }

            if (result.success) {
                dispatch(authActions.logout({}));
                dispatch(messageActions.create({ message: 'Logged out.', severity: 'success' }));
            } else if (result.response.statusCode === 403) {
                dispatch(authActions.logout({ message: 'Unable to logout.' }));
            } else {
                dispatch(messageActions.create({ message: result.response.message, severity: 'error' }));
            }
        }

        history.push(item.pageUrl);
        setAnchorEl(null);
    };

    return (
        <>
            {!isAuthenticated && (
                <Button component={Link} to="/auth/login">
                    Login
                </Button>
            )}
            {isAuthenticated && (
                <div>
                    <IconButton
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleMenu}
                        color="inherit"
                        size="large"
                    >
                        <AccountCircle />
                        <Typography variant="h6" sx={{ px: 2, py: 0 }}>
                            {displayName}
                        </Typography>
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={open}
                        onClose={handleClose}
                    >
                        {USER_MENU.map((item) => (
                            <MenuItem
                                key={item.id}
                                onClick={() => handleMenuClick(item)}
                            >
                                {item.title}
                            </MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
        </>
    );
};

export default TopMenu;
