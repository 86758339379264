import axios from 'axios';
import { baseUrl } from '../config/config';

export const sendRequest = async (config) => {
    const req = {
        ...config.addlProperties,
        method: config.method ? config.method : 'GET',
        headers: config.headers
            ? config.headers
            : {
                  'Content-Type': 'application/json',
              },
        url: config.url,
        data: config.body ? JSON.stringify(config.body) : null,
        maxRedirects: 0,
        timeout: config.apiTimeout,
        withCredentials: true,
        //   httpsAgent: new https.Agent({ rejectUnauthorized: false }),
        validateStatus: (status) => status < 500,
    };

    try {
        const response = await axios(req);
        const data = await response.data;
        return data;
    } catch (err) {
        throw err;
    }
};

export const putRequest = async (url, body) => {
    let result;

    try {
        result = await sendRequest({
            url: `${baseUrl}${url}`,
            method: 'PUT',
            body: body,
        });
    } catch (err) {
        throw err;
    }

    return result;
};

/**
 * @name buildQueryParams
 * @description Build the query parameters portion of the URL
 *      and return the string
 * @param {object} params Parameters to configure URL
 */
export const buildQueryParams = (params) => {
    let sep = '?';
    let url = '';

    if (params) {
        for (const param in params) {
            if (param === 'filter') {
                const filterParams = params[param];
                for (const key in filterParams) {
                    if (Array.isArray(filterParams[key])) {
                        for (const element of filterParams[key]) {
                            for (const op in element) {
                                url += `${sep}filter[${key}][${op}]=${element[op]}`;
                                sep = sep === '?' ? '&' : sep;
                            }
                        }
                    } else if (typeof filterParams[key] === 'object') {
                        let operatorObj = filterParams[key];

                        for (const op in operatorObj) {
                            url += `${sep}filter[${key}][${encodeURIComponent(op)}]=${operatorObj[op]}`;
                            sep = sep === '?' ? '&' : sep;
                        }
                    } else {
                        url += `${sep}filter[${key}]=${filterParams[key]}`;
                        sep = sep === '?' ? '&' : sep;
                    }
                }
            } else {
                url += `${sep}${param}=${params[param]}`;
                sep = sep === '?' ? '&' : sep;
            }
        }
    }

    return url;
};

export const getRequest = async (config) => {
    let result;
    let url;
    let params;

    if (typeof config === 'object') {
        url = config.url;
        params = config.params;

        if (config.id) {
            url += `/${config.id}`;
        }
    } else {
        url = config;
    }

    try {
        url += buildQueryParams(params);

        result = await sendRequest({
            url: `${baseUrl}${url}`,
        });
    } catch (err) {
        throw err;
    }

    return result;
};

export const getPdf = async (config) => {
    let result;
    let url;
    let params;

    if (typeof config === 'object') {
        url = config.url;
        params = config.params;

        if (config.id) {
            url += `/${config.id}`;
        }
    } else {
        url = config;
    }

    try {
        url += buildQueryParams(params);

        result = await sendRequest({
            url: `${baseUrl}${url}`,
            addlProperties: {
                responseType: 'stream',
            },
            headers: {
                Accept: 'application/pdf, application/json',
            },
        });
    } catch (err) {
        throw err;
    }

    return result;
};

// export const postRequest = async (url, body) => {
//     let result;

//     try {
//         result = await sendRequest({
//             url: `${baseUrl}${url}`,
//             method: 'POST',
//             body: body,
//         });
//     } catch (err) {
//         throw err;
//     }

//     return result;
// };

export const postRequest = async (config) => {
    let result;

    try {
        result = await sendRequest({
            url: `${baseUrl}${config.url}`,
            method: 'POST',
            body: config.body,
        });
    } catch (err) {
        throw err;
    }

    return result;
};

export const deleteRequest = async (url) => {
    let result;

    try {
        result = await sendRequest({
            url: `${baseUrl}${url}`,
            method: 'DELETE',
        });
    } catch (err) {
        throw err;
    }

    return result;
};

/**
 * End of file
 */
