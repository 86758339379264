import { useState } from 'react';
import { Menu, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { checkAuth } from '../../lib/check-auth';

import ScheduleIcon from '@mui/icons-material/Schedule';
import ViewListIcon from '@mui/icons-material/ViewList';
import SubMenu2 from './SubMenu2';

const SubMenu = ({ item, index }) => {
    const authData = useSelector((state) => state.auth);
    let isAuthorized = checkAuth(authData, { role: item.role, perm: item.permission, op: item.op });
    const [anchorEl, setAnchorEl] = useState(null);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderIcon = (icon) => {
        switch (icon) {
            case 'Schedule':
                return <ScheduleIcon />;
            case 'ViewList':
                return <ViewListIcon />;
            default:
                return <></>;
        }
    };

    return (
        <>
            {!item.items && isAuthorized && (
                <ListItem button component={Link} to={item.pageUrl} key={item.id}>
                    <ListItemIcon>{item.icon && renderIcon(item.icon)}</ListItemIcon>
                    <ListItemText primary={item.title} />
                </ListItem>
            )}
            {item.items && isAuthorized && (
                <>
                    <ListItem
                        button
                        onClick={handleMenu}
                        key={item.id}
                        aria-label="menu item button"
                        aria-controls="{item.id}"
                        aria-haspopup="true"
                    >
                        <ListItemIcon>{item.icon && renderIcon(item.icon)}</ListItemIcon>
                        <ListItemText primary={item.title} />
                    </ListItem>
                    <Menu
                        id={item.id}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                    >
                        {item.items.map((subitem, index) => (
                            <SubMenu2 key={index} item={subitem} index={index} clickHandler={handleClose} />
                        ))}
                    </Menu>
                </>
            )}
        </>
    );
};

export default SubMenu;
